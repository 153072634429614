import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function EditLegendSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <rect width="24" height="24" rx="6" fill="#1A62F2" />
            <g mask="url(#mask0_1185_38428)">
                <path
                    d="M7.91667 16.0833H8.74792L14.45 10.3812L13.6188 9.55L7.91667 15.2521V16.0833ZM6.75 17.25V14.7708L14.45 7.08542C14.5667 6.97847 14.6955 6.89583 14.8365 6.8375C14.9774 6.77917 15.1257 6.75 15.2812 6.75C15.4368 6.75 15.5875 6.77917 15.7333 6.8375C15.8792 6.89583 16.0056 6.98333 16.1125 7.1L16.9146 7.91667C17.0312 8.02361 17.1163 8.15 17.1698 8.29583C17.2233 8.44167 17.25 8.5875 17.25 8.73333C17.25 8.88889 17.2233 9.03715 17.1698 9.17812C17.1163 9.3191 17.0312 9.44792 16.9146 9.56458L9.22917 17.25H6.75ZM14.0271 9.97292L13.6188 9.55L14.45 10.3812L14.0271 9.97292Z"
                    fill="white"
                />
            </g>
        </SvgIcon>
    );
}
