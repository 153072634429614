import { RHFTextField } from "@convin/components/hook-form";
import RHFDatePicker from "@convin/components/hook-form/RHFDatePicker";
import { isDefined } from "@convin/utils/helper/common.helper";
import {
    Autocomplete,
    Box,
    createFilterOptions,
    TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const filterOptions = createFilterOptions({
    ignoreCase: false,
});

function InputValue() {
    const { watch, control } = useFormContext();

    const dataType = watch("dataType");
    const operator = watch("operator");

    if (operator === "in") {
        return (
            <Controller
                name="list"
                control={control}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <Autocomplete
                            multiple
                            open={false}
                            options={field.value}
                            freeSolo
                            filterOptions={filterOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Enter Information"
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                            isOptionEqualToValue={(option, value) =>
                                option === value
                            }
                            value={field.value}
                            onChange={(_, value) => {
                                const isNumType = dataType === "array_float";

                                if (isNumType) {
                                    if (
                                        value
                                            .map((e) => Number(e))
                                            .some((e) => isNaN(e))
                                    )
                                        return;
                                }
                                const uniqueValues = Object.fromEntries(
                                    (value as (string | number)[]).map((s) => [
                                        typeof s === "number"
                                            ? s
                                            : s.toLowerCase(),
                                        s,
                                    ])
                                );
                                field.onChange(
                                    Object.values(uniqueValues).map((e) =>
                                        isNumType ? Number(e) : String(e)
                                    )
                                );
                            }}
                            size="small"
                        />
                    );
                }}
            />
        );
    }

    return (
        <>
            {["float", "array_float"].includes(dataType) ||
            ["same_day", "same_month", "same_year"].includes(operator) ? (
                <>
                    {operator === "between" ? (
                        <Box className="flex items-center" gap={2}>
                            <RHFTextField
                                type="number"
                                label="Enter Min Value"
                                name="min"
                                size="small"
                            />
                            <RHFTextField
                                type="number"
                                label="Enter Max Value"
                                name="max"
                                size="small"
                            />
                        </Box>
                    ) : (
                        <RHFTextField
                            type="number"
                            label="Enter Information"
                            name="value"
                            size="small"
                        />
                    )}
                </>
            ) : dataType === "date" ? (
                operator !== "between" ? (
                    <RHFDatePicker name={"value"} />
                ) : (
                    <Box className="flex items-center" gap={2}>
                        <RHFDatePicker
                            name={"min"}
                            datePickerProps={{
                                label: "(MM/DD/YYYY)",
                                format: "MM-dd-yyyy",
                            }}
                        />
                        <RHFDatePicker
                            name={"max"}
                            datePickerProps={{
                                label: "(MM/DD/YYYY)",
                                format: "MM-dd-yyyy",
                            }}
                        />
                    </Box>
                )
            ) : (
                <RHFTextField
                    label="Enter Information"
                    name="value"
                    size="small"
                    disabled={!isDefined(operator)}
                />
            )}
        </>
    );
}

export default InputValue;
