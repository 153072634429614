import { Skeleton, Stack, StackProps } from "@mui/material";
import { ReactElement, memo } from "react";

interface CustomSkeletonProps {
    rows: number;
    gap?: number;
}

export default function CustomSkeleton({
    rows,
    gap = 2,
    ...rest
}: CustomSkeletonProps & StackProps): ReactElement {
    return (
        <Stack className="w-full" gap={gap} {...rest}>
            {new Array(rows).fill(0).map((_, idx) => (
                <Skeleton
                    key={idx}
                    animation="wave"
                    sx={{
                        width: "100%",
                    }}
                />
            ))}
        </Stack>
    );
}
