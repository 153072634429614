import {
    PermissionModules,
    PermissionTypes,
} from "@convin/config/permissions.config";
import { RouteType } from "@convin/config/routes.config";
import { UserType } from "@convin/type/User";
import { createContext } from "react";

export type CheckCanAccessParams = {
    heading: PermissionModules;
    code_name?: string;
    permission_type?: PermissionTypes;
};
export interface IAuthContextSate {
    user: UserType | null;
    checkIsOwner: (id: number | undefined | null) => boolean;
    isAuditor: boolean;
    logout: () => void;
    checkCanAccess: ({
        heading,
        code_name,
        permission_type,
    }: CheckCanAccessParams) => boolean;
    checkDashboardVisibility: (e: RouteType) => boolean;
}

const AuthContext = createContext<IAuthContextSate | null>(null);
// ----------------------------------------------------------------------

export default AuthContext;
