import { Box } from "@mui/material";
import { BoxProps } from "@mui/system";

export default function AddButton({ sx, className, ...rest }: BoxProps) {
    return (
        <Box
            sx={{
                background: "#000",
                color: "#fff",
                borderRadius: "50%",
                display: "flex",
                lineHeight: "8px",
                ...sx,
            }}
            className={[
                "items-center justify-center h-[40px] w-[40px]",
                className,
            ]
                .filter(Boolean)
                .join(" ")}
            component="button"
            {...rest}
        >
            +
        </Box>
    );
}
