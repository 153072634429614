import { useFormContext, Controller } from "react-hook-form";
import { Box, FormHelperText, TextField, TextFieldProps } from "@mui/material";
import { isDefined } from "@convin/utils/helper/common.helper";

// ----------------------------------------------------------------------

export default function RHFTextField({
    name = "",
    onChange,
    value,
    charLimit,
    type,
    ...other
}: TextFieldProps & { charLimit?: number }) {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <Box className="relative w-full">
                        <TextField
                            {...field}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                            type={type}
                            {...other}
                            onChange={(e) => {
                                field.onChange(
                                    type === "number"
                                        ? Number(e.target.value)
                                        : e.target.value
                                );
                                onChange?.(e);
                            }}
                            value={value ?? field.value ?? ""}
                        />
                        {isDefined(charLimit) && (
                            <FormHelperText
                                error={String(field.value).length > charLimit}
                                className="absolute right-0"
                            >
                                {`${
                                    typeof field.value === "string"
                                        ? field?.value?.length
                                        : 0
                                }/${charLimit} Characters`}
                            </FormHelperText>
                        )}
                    </Box>
                );
            }}
        />
    );
}
