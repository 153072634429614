import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "font-awesome/css/font-awesome.min.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { disableReactDevTools } from "./tools";
import { store } from "@store/index";
//convin - mui - theme - imports
import { SettingsProvider } from "@convin/contexts/SettingsContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ThemeProvider from "@convin/theme";
import ThemeColorPresets from "@convin/components/theme/ThemeColorPresets";
import ThemeSettings from "@convin/components/theme/settings";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import EditorStyle from "@convin/styleOverrides/EditorStyle";
import ChartStyle from "@convin/components/chart/ChartStyle";
import { PosthogWrapper } from "PosthogWrapper";
import SalesAssistWidget from "@convin/components/custom_components/SalesAssistIframe/SalesAssistWidget";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

// REMOVE react dev tools for production build to avoid state manipulation by user
if (import.meta.env.PROD) {
    disableReactDevTools();
}

root.render(
    <Provider store={store}>
        <PosthogWrapper>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                    <BrowserRouter>
                        <ThemeProvider>
                            <ThemeColorPresets>
                                <EditorStyle />

                                <ChartStyle />
                                {/* <ReactMentionStyles /> */}
                                <App />
                                <ThemeSettings />
                            </ThemeColorPresets>
                        </ThemeProvider>
                        <ToastContainer
                            position="top-right"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <SalesAssistWidget />
                    </BrowserRouter>
                </SettingsProvider>
            </LocalizationProvider>
        </PosthogWrapper>
    </Provider>
);
