import { Box, Popover, Skeleton } from "@mui/material";
import {
    getParameterResponseOptions,
    isDefined,
} from "@convin/utils/helper/common.helper";
import BlockSettingSvg from "../../svg/BlockSettingSvg";
import useAnchorEl from "@convin/hooks/useAnchorEl";
import CreateSingleResponseBlockType from "../SingleResponse/CreateSingleResponseBlockType";
import BertCardWrapper from "../BertCardWrapper";
import EditLegendSvg from "../../svg/EditLegendSvg";
import DeleteLegendSvg from "../../svg/DeleteLegendSvg";
import NotOperatorLegendSvg from "../../svg/NotOperatorLegendSvg";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import NodeStatePorvider from "../../context/NodeStatePorvider";
import AttributeBlock from "../Blocks/AttributeBlock";
import ParameterBlock from "../Blocks/ParameterBlock";
import MomentsBlock from "../Blocks/MomentsBlock";
import PredefinedFuncBlock from "../Blocks/PredefinedFuncBlock";
import {
    MomentReferenceType,
    MultiResponseBlockData,
    MultipleResponseNode,
} from "../../../types/bert";
import { Node } from "reactflow";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import { useGetMomentsQuery } from "@convin/redux/services/settings/auditAiConfig.service";
import InfoLegendSvg from "../../svg/InfoLegendSvg";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";
import ValidateInfoBlock from "../Blocks/ValidateInfoBlock";
import { MultiResponseNodeType } from "../../context/NodeStateContext";
import { pxToRem } from "@convin/utils/getFontValue";

export default function MultiResponseBlock({
    data,
    id,
    parent,
    isConditionBlock,
    sourcePosition,
    hideDelete,
    hideNot,
}: MultiResponseBlockData<MultiResponseNodeType>["blocks"][0] & {
    isConditionBlock?: boolean;
    hideDelete?: boolean;
    hideNot?: boolean;
}) {
    const { question } = useParameterAiConfigContext();
    const { data: momentsObj, isLoading } = useGetMomentsQuery();
    const { setNodes, setEdges } = useReactFlowStateContextProvider();
    const {
        dispatch,
        aiConfigState: { activeGroupBlock },
    } = useParameterAiConfigContext();
    const { open, anchorEl, handleClick, handleClose } =
        useAnchorEl<HTMLButtonElement | null>();

    const {
        open: toolBarOpen,
        handleClick: handleToolbarClick,
        handleClose: handleToolbarClose,
        anchorEl: toolbarAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();

    const {
        open: editOpen,
        anchorEl: editAnchor,
        handleClick: handleEditClick,
        handleClose: handleEditClose,
    } = useAnchorEl<HTMLButtonElement | null>();
    const {
        open: infoOpen,
        handleClick: handleInfoClick,
        handleClose: handleInfoClose,
        anchorEl: infoAnchor,
    } = useAnchorEl<HTMLButtonElement | null>();

    const parameterOptions = isConditionBlock
        ? []
        : getParameterResponseOptions(question);

    const isMommentBlock = data.type === "moment";
    const momentName = isMommentBlock
        ? momentsObj?.moments.find(
              (e) => e.id === (data.metadata as MomentReferenceType).id
          )?.name
        : "";

    return (
        <>
            <div
                className={[
                    "multiple--response-block",
                    data.is_not && "not-operator-block",
                    id === activeGroupBlock?.blockId && "active-group-block",
                    !isDefined(data.type) && "no-type-block",
                ]
                    .filter(Boolean)
                    .join(" ")}
            >
                {isDefined(data.type) && (
                    <>
                        {data.type === "group_block" ? (
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    rx="4"
                                    fill="#F26EA6"
                                    stroke="#EA62B4"
                                />

                                <path
                                    d="M8.97201 12.5875L5.39844 9.80805L6.05359 9.31172L8.97201 11.575L11.8904 9.31172L12.5456 9.80805L8.97201 12.5875ZM8.97201 10.5823L5.39844 7.80288L8.97201 5.02344L12.5456 7.80288L8.97201 10.5823Z"
                                    fill="white"
                                />
                            </svg>
                        ) : (
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    rx="4"
                                    fill="#F9A641"
                                    stroke="#F9A641"
                                />
                                <path
                                    d="M9.12648 12.8311L5.4375 9.1421L9.12648 5.45312L12.8155 9.1421L9.12648 12.8311Z"
                                    fill="white"
                                />
                            </svg>
                        )}
                        {data.is_not && (
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="16"
                                    height="16"
                                    rx="3.75"
                                    fill="#FD6C3E"
                                />
                                <path
                                    d="M8.00521 4.65625C6.16521 4.65625 4.67188 6.14958 4.67188 7.98958C4.67188 9.82958 6.16521 11.3229 8.00521 11.3229C9.84521 11.3229 11.3385 9.82958 11.3385 7.98958C11.3385 6.14958 9.84521 4.65625 8.00521 4.65625ZM8.00521 10.6562C6.53188 10.6562 5.33854 9.46292 5.33854 7.98958C5.33854 7.37292 5.54854 6.80625 5.90188 6.35625L9.63854 10.0929C9.18854 10.4462 8.62188 10.6562 8.00521 10.6562ZM10.1085 9.62292L6.37188 5.88625C6.82188 5.53292 7.38854 5.32292 8.00521 5.32292C9.47854 5.32292 10.6719 6.51625 10.6719 7.98958C10.6719 8.60625 10.4619 9.17292 10.1085 9.62292Z"
                                    fill="white"
                                />
                            </svg>
                        )}
                    </>
                )}
                <div
                    className="flex-1 font-semibold text-ellipsis overflow-hidden whitespace-nowrap"
                    style={{
                        fontSize: pxToRem(12),
                    }}
                >
                    {isConditionBlock ? (
                        isMommentBlock ? (
                            isLoading ? (
                                <Skeleton />
                            ) : (
                                momentName
                            )
                        ) : (
                            data.metadata.name
                        )
                    ) : (
                        parameterOptions.find((e) => e.id == id)?.label
                    )}
                </div>
                <>
                    {isDefined(data.type) ? (
                        <button
                            onClick={handleToolbarClick}
                            className="flex-shrink-0 nodrag nopan cursor-pointer font-semibold scale-[1.2]"
                        >
                            &#8942;
                        </button>
                    ) : (
                        <>
                            <button
                                className="flex-shrink-0 nodrag nopan cursor-pointer"
                                onClick={handleClick}
                            >
                                <BlockSettingSvg />
                            </button>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left",
                                }}
                                classes={{
                                    paper: "overflow-visible bg-transparent",
                                }}
                            >
                                <Box
                                    className="relative"
                                    sx={{
                                        width: "420px",
                                    }}
                                >
                                    <BertCardWrapper
                                        sx={{
                                            bottom: "0",
                                            left: "0",
                                            transform: "translate(0%,50%)",
                                            position: "absolute",
                                        }}
                                    >
                                        <CreateSingleResponseBlockType
                                            onNodeSaveCallBack={() => {
                                                handleClose();
                                            }}
                                            sourceId={id}
                                            nodeDataToUpdate={data.metadata}
                                            blockParentId={parent ?? undefined}
                                        />
                                    </BertCardWrapper>
                                </Box>
                            </Popover>
                        </>
                    )}
                </>
                {toolBarOpen && (
                    <Popover
                        anchorEl={toolbarAnchor}
                        open={toolBarOpen}
                        onClose={handleToolbarClose}
                        transformOrigin={{
                            horizontal: -40,
                            vertical: "center",
                        }}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "center",
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    bgcolor: "transparent",
                                    border: "none",
                                },
                            },
                        }}
                    >
                        <div className="flex items-center flex-col gap-[8px]">
                            {data.type !== "group_block" && (
                                <button onClick={handleInfoClick}>
                                    <InfoLegendSvg />
                                </button>
                            )}

                            <button
                                onClick={(e) => {
                                    if (data.type === "group_block") {
                                        dispatch({
                                            type: "SET_ACTIVE_GROUP_BLOCK_ID",
                                            payload: {
                                                blockId: id,
                                                blockParentId: parent!,
                                            },
                                        });
                                    } else {
                                        handleEditClick(e);
                                    }
                                }}
                            >
                                <EditLegendSvg />
                            </button>
                            {!hideDelete && (
                                <DeleteButton
                                    onDelete={() => {
                                        return new Promise(() => {
                                            setNodes((nodes) =>
                                                nodes.map(
                                                    (
                                                        node: Node<
                                                            MultipleResponseNode<MultiResponseNodeType>
                                                        >
                                                    ) =>
                                                        node.id === parent
                                                            ? {
                                                                  ...node,
                                                                  data: {
                                                                      ...node.data,
                                                                      metadata:
                                                                          {
                                                                              ...node
                                                                                  .data
                                                                                  .metadata,
                                                                              blocks: node.data.metadata.blocks.filter(
                                                                                  (
                                                                                      b
                                                                                  ) =>
                                                                                      b.id !==
                                                                                      id
                                                                              ),
                                                                          },
                                                                  },
                                                              }
                                                            : node
                                                )
                                            );
                                            setEdges((edges) =>
                                                edges.filter(
                                                    (e) =>
                                                        e.sourceHandle !==
                                                        `${id}_${sourcePosition}`
                                                )
                                            );

                                            handleToolbarClose();
                                        });
                                    }}
                                    message="Are you sure you want to delete the block?"
                                    title="Delete Block"
                                    DeleteOptionComponent={
                                        <button>
                                            <DeleteLegendSvg />
                                        </button>
                                    }
                                />
                            )}
                            {!hideNot && (
                                <button
                                    onClick={() => {
                                        setNodes((nodes) =>
                                            nodes.map(
                                                (
                                                    node: Node<
                                                        MultipleResponseNode<MultiResponseNodeType>
                                                    >
                                                ) =>
                                                    node.id === parent
                                                        ? {
                                                              ...node,
                                                              data: {
                                                                  ...node.data,
                                                                  metadata: {
                                                                      ...node
                                                                          .data
                                                                          .metadata,
                                                                      blocks: node.data.metadata.blocks.map(
                                                                          (b) =>
                                                                              b.id ===
                                                                              id
                                                                                  ? {
                                                                                        ...b,
                                                                                        data: {
                                                                                            ...b.data,
                                                                                            is_not: !b
                                                                                                .data
                                                                                                .is_not,
                                                                                        },
                                                                                    }
                                                                                  : b
                                                                      ),
                                                                  },
                                                              },
                                                          }
                                                        : node
                                            )
                                        );
                                        handleToolbarClose();
                                    }}
                                >
                                    <NotOperatorLegendSvg />
                                </button>
                            )}
                        </div>
                    </Popover>
                )}
                {(editOpen || infoOpen) && (
                    <Popover
                        open={editOpen || infoOpen}
                        anchorEl={editAnchor || infoAnchor}
                        onClose={editOpen ? handleEditClose : handleInfoClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        sx={{
                            ".MuiPaper-root": {
                                mt: 0,
                            },
                        }}
                    >
                        <NodeStatePorvider
                            onNodeSaveCallBack={() => {
                                handleToolbarClose();
                                handleEditClose();
                            }}
                            nodeIdToUpdate={id}
                            nodeDataToUpdate={data.metadata}
                            blockParentId={parent!}
                        >
                            <BertCardWrapper
                                className="w-[400px]"
                                sx={{
                                    mt: 2,
                                    ...((infoOpen || !editOpen) && {
                                        "& button": {
                                            display: "none",
                                        },

                                        "& *": {
                                            userSelect: "none",
                                            pointerEvents: "none",
                                        },
                                    }),
                                }}
                            >
                                {data.type === "attribute" ? (
                                    <AttributeBlock />
                                ) : (
                                    <></>
                                )}
                                {data.type === "parameter" ? (
                                    <ParameterBlock />
                                ) : (
                                    <></>
                                )}
                                {data.type === "moment" ? (
                                    <MomentsBlock />
                                ) : (
                                    <></>
                                )}
                                {data.type === "function" ? (
                                    <PredefinedFuncBlock />
                                ) : (
                                    <></>
                                )}
                                {data.type === "validateInfo" ? (
                                    <ValidateInfoBlock />
                                ) : (
                                    <></>
                                )}
                            </BertCardWrapper>
                        </NodeStatePorvider>
                    </Popover>
                )}
            </div>
        </>
    );
}
