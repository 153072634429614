import { isDefined } from "@convin/utils/helper/common.helper";
import { Box } from "@mui/material";
import { PropsWithChildren, useRef, useState } from "react";
import AddHandler from "./AddHandler";
import { Handle, NodeProps, Position } from "reactflow";
import BackDrop from "../BackDrop";
import { NodeStateProviderProps } from "../../context/NodeStateContext";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import useAnchorEl from "@convin/hooks/useAnchorEl";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import { BertNodeKeys } from "../../constants";

export default function HandlerWrapper({
    children,
    AddComponent,
    id,
    blockId,
    isConnectable,
    targetPosition,
    sourcePosition,
    hideSourceHandles,
    type,
}: PropsWithChildren<
    {
        AddComponent: React.ComponentType<
            Pick<
                NodeStateProviderProps,
                "sourceId" | "direction" | "onNodeSaveCallBack" | "blockId"
            >
        >;
    } & NodeProps & { blockId?: string; hideSourceHandles?: boolean }
>) {
    const { isMultiResponseTree } = useParameterAiConfigContext();
    const { edges, isGroupBlockTree } = useReactFlowStateContextProvider();
    const { anchorEl, handleClick, handleClose, open } =
        useAnchorEl<HTMLDivElement>();
    const [pos, setPos] = useState({
        right: false,
        center: false,
        left: false,
    });

    const ref = useRef<HTMLDivElement | null>(null);

    const isMouseInBound = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (!isDefined(ref.current)) return;
        const eleBounds = ref.current.getBoundingClientRect();
        let ret = false;
        if (e.clientX >= eleBounds.left && e.clientX <= eleBounds.right) {
            ret = true;
        } else {
            ret = false;
        }
        return ret;
    };

    const isSource = isDefined(
        edges.find(
            (e) =>
                e.sourceHandle ===
                `${
                    isMultiResponseTree && !isGroupBlockTree ? blockId : id
                }_${sourcePosition}`
        )
    );

    const handlerId = isMultiResponseTree && !isGroupBlockTree ? blockId : id;
    return (
        <>
            <Box
                className="relative flex-1"
                ref={ref}
                onMouseEnter={isMouseInBound}
                onMouseMove={(e) => {
                    if (open) return;
                    if (!isDefined(ref.current)) return;
                    const eleBounds = ref.current.getBoundingClientRect();
                    const rightPosition = eleBounds.right - e.clientX; // range lies below 20
                    const leftPosition = Math.abs(eleBounds.left - e.clientX); // range lies below 20

                    const centerPositon =
                        (eleBounds.left + eleBounds.right) / 2 - e.clientX; // range lies between [-20,20]
                    if (
                        (centerPositon < 20 || centerPositon > -20) &&
                        !pos.center &&
                        rightPosition > 50 &&
                        !isMultiResponseTree
                    ) {
                        setPos({ center: true, right: false, left: false });
                        return;
                    }

                    if (rightPosition < 40 && !pos.right) {
                        setPos({ center: false, right: true, left: false });
                    }

                    if (leftPosition < 40 && !pos.left) {
                        setPos({ center: false, right: false, left: true });
                    }
                }}
                sx={{
                    ".react-flow__handle.connectionindicator": {
                        ...(type !== BertNodeKeys.conditionalNode &&
                            type !== BertNodeKeys.responseNode &&
                            type !== BertNodeKeys.conditionalGptNode && {
                                pointerEvents: "none",
                            }),
                    },
                }}
            >
                {children}
                {isDefined(sourcePosition) ? (
                    <Handle
                        type="source"
                        id={`${handlerId}_${sourcePosition}`}
                        position={sourcePosition}
                        className={isSource ? "" : "invisible"}
                    />
                ) : (
                    <></>
                )}
                {(!isMultiResponseTree || isGroupBlockTree) &&
                    isConnectable &&
                    isDefined(targetPosition) && (
                        <Handle
                            type={"target"}
                            id={`${id}`}
                            position={targetPosition!}
                        />
                    )}
                <div
                    style={{
                        ...((isSource || hideSourceHandles) && {
                            visibility: "hidden",
                            pointerEvents: "none",
                        }),
                    }}
                >
                    <>
                        {isMultiResponseTree && !isGroupBlockTree && (
                            <AddHandler
                                type="source"
                                id={`${handlerId}_left`}
                                position={Position.Left}
                                className={
                                    isGroupBlockTree
                                        ? ""
                                        : pos.left
                                        ? ""
                                        : "invisible"
                                }
                                {...{
                                    handleClick,
                                    handleClose,
                                    anchorEl,
                                }}
                            >
                                <AddComponent
                                    blockId={blockId}
                                    direction={Position.Left}
                                    sourceId={id}
                                    onNodeSaveCallBack={() => {
                                        handleClose();
                                    }}
                                />
                            </AddHandler>
                        )}
                        <AddHandler
                            type="source"
                            id={`${handlerId}_right`}
                            position={Position.Right}
                            className={
                                isGroupBlockTree
                                    ? ""
                                    : pos.right
                                    ? ""
                                    : "invisible"
                            }
                            {...{
                                handleClick,
                                handleClose,
                                anchorEl,
                            }}
                        >
                            <AddComponent
                                blockId={blockId}
                                direction={Position.Right}
                                sourceId={id}
                                onNodeSaveCallBack={() => {
                                    handleClose();
                                }}
                            />
                        </AddHandler>
                    </>
                </div>
            </Box>
            {open && (
                <div
                    className="absolute flex items-center justify-center"
                    style={{
                        zIndex: 1,
                    }}
                >
                    <BackDrop
                        onClick={() => {
                            handleClose();
                        }}
                    />
                </div>
            )}
        </>
    );
}
