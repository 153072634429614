import useSearchQuery from "@convin/hooks/useSearchQuery";

export default function SalesAssistWidget() {
    const query = useSearchQuery();
    const id = query.get("widget_id");
    if (!id) {
        return null;
    }
    return (
        <iframe
            loading="lazy"
            title="SaleAssistWidget"
            src={
                "https://jump-stg.saleassist.ai/source/47bccdde-38df-4bd0-8821-585da100e8d7"
            }
            sandbox="allow-scripts allow-modals allow-forms allow-popups allow-same-origin allow-top-navigation"
            allowFullScreen
            className="h-full w-full absolute bottom-0 left-0"
        ></iframe>
    );
}
