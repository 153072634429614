import { FormControl, FormControlProps } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";

export default function RHFDatePicker({
    formControlProps,
    datePickerProps,
    name,
}: {
    formControlProps?: FormControlProps;
    datePickerProps?: DatePickerProps<unknown>;
    name: string;
}) {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl {...formControlProps}>
                    <DatePicker
                        {...field}
                        format="yyyy-MM-dd"
                        label={"Enter Date"}
                        disableFuture
                        value={field.value || null}
                        onChange={(value) => field.onChange(value)}
                        slotProps={{
                            textField: {
                                size: "small",
                                error: !!error,
                                helperText: error?.message,
                            },
                        }}
                        views={["year", "month", "day"]}
                        {...datePickerProps}
                    />
                </FormControl>
            )}
        />
    );
}
