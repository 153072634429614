import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function ValidateInfoSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <path
                d="M6.60938 12.4844H13.4871"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
            />
            <path
                d="M6.60938 9.6875H13.4871"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
            />
            <path
                d="M6.60938 6.875H13.4871"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
            />
            <path
                d="M8.55212 16.4716H4.56397C4.15778 16.4734 3.76694 16.3165 3.47478 16.0343C3.18262 15.7521 3.01226 15.3669 3 14.9609V4.51204C2.99982 4.31361 3.03873 4.11707 3.11452 3.93368C3.19031 3.75028 3.30149 3.58362 3.44171 3.4432C3.58193 3.30279 3.74844 3.19137 3.93173 3.11533C4.11502 3.03928 4.3115 3.00009 4.50994 3H15.3714C15.5699 3 15.7665 3.03912 15.9498 3.11512C16.1332 3.19113 16.2998 3.30253 16.4401 3.44295C16.5804 3.58338 16.6916 3.75007 16.7675 3.93352C16.8433 4.11696 16.8822 4.31355 16.882 4.51204V10.643"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
            />
            <path
                d="M13.7199 18.7917C13.5753 18.7924 13.4321 18.7624 13.2998 18.7039C13.1676 18.6454 13.0491 18.5596 12.9523 18.4521L10.8733 16.369C10.7745 16.2719 10.696 16.156 10.6426 16.0282C10.5892 15.9003 10.562 15.7631 10.5625 15.6245C10.563 15.4831 10.5919 15.3433 10.6476 15.2133C10.7033 15.0834 10.7846 14.966 10.8867 14.8681C11.092 14.6856 11.3584 14.587 11.633 14.5918C11.9077 14.5966 12.1705 14.7045 12.3692 14.8941L13.7199 16.249L17.1881 12.7695C17.3914 12.5789 17.6603 12.474 17.9389 12.4766C18.2175 12.4792 18.4844 12.5892 18.684 12.7835C18.7794 12.8795 18.8549 12.9934 18.9061 13.1186C18.9573 13.2438 18.9832 13.378 18.9822 13.5133C18.9819 13.6529 18.9539 13.791 18.8997 13.9197C18.8455 14.0483 18.7662 14.1649 18.6665 14.2626L14.4615 18.4809C14.3658 18.5807 14.2506 18.6598 14.123 18.7133C13.9954 18.7667 13.8582 18.7934 13.7199 18.7917Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
