import {
    FormProvider,
    RHFSelect,
    RHFTextField,
} from "@convin/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useNodeContext from "../../hooks/useNodeContext";
import { predefinedFunctionOptions } from "../../constants";
import {
    PredefinedFuncBlockFormType,
    SingleResponseNode,
} from "../../../types/bert";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import BlockHeader from "./BlockHeader";
import { isDefined } from "@convin/utils/helper/common.helper";
import { useEffect, useMemo, useRef, useState } from "react";

export default function PredefinedFuncBlock({
    showCreateButton = true,
}: {
    showCreateButton?: boolean;
}) {
    const canUpdate = useRef<boolean>(false);
    const {
        sourceId,
        direction,
        onNodeSaveCallBack,
        nodeIdToUpdate,
        nodeDataToUpdate,
        blockParentId,
    } = useNodeContext();

    const { createSingleResponseNode, updateSingleResponseNode } =
        useReactFlowStateContextProvider();

    const [isBlankCall, setIsBlackCall] = useState<boolean>(false);
    const genetateSchemaObject = useMemo(() => {
        const schemaObject: Record<string, Yup.AnySchema> = {
            name: Yup.string().required("Name is Required"),
            function_type: Yup.string().required("Function is Required"),
            min: Yup.number()
                .transform((value, originalValue) =>
                    String(originalValue).trim() === "" ? null : value
                )
                .nullable()
                .typeError("must be a number")
                .when("function_type", {
                    is: (
                        function_type: PredefinedFuncBlockFormType["function_type"]
                    ) => function_type === "blank_call",
                    then: (s) =>
                        s.max(100, "Percentage should be less than 100"),
                    otherwise: (s) => s,
                }),
            max: Yup.number()
                .transform((value, originalValue) =>
                    String(originalValue).trim() === "" ? null : value
                )
                .nullable()
                .typeError("must be a number"),
        };

        return isBlankCall
            ? Yup.object().shape(schemaObject)
            : Yup.object()
                  .shape(schemaObject)
                  .test(
                      "min-or-max-required",
                      "Either Min or Max is required",
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      function (value: any) {
                          if (value.min === null && value.max === null) {
                              return this.createError({
                                  path: "min",
                                  message: "Either Min or Max is required",
                              });
                          }
                          return true;
                      }
                  )
                  .test(
                      "min-less-than-max",
                      "Min should not exceed Max",
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      function (value: any) {
                          if (
                              value.min !== null &&
                              value.max !== null &&
                              value.min > value.max
                          ) {
                              return this.createError({
                                  path: "min",
                                  message: "Min should not exceed Max",
                              });
                          }
                          return true;
                      }
                  );
    }, [isBlankCall]);

    const methods = useForm<PredefinedFuncBlockFormType>({
        resolver: yupResolver(genetateSchemaObject),
        values: isDefined(nodeDataToUpdate)
            ? { ...(nodeDataToUpdate as PredefinedFuncBlockFormType) }
            : {
                  name: "",
                  function_type: "",
                  min: null,
                  max: null,
              },
    });

    const onSubmit = async (values: PredefinedFuncBlockFormType) => {
        if (isDefined(nodeIdToUpdate)) {
            updateSingleResponseNode<PredefinedFuncBlockFormType>({
                data: values,
                sourceId: nodeIdToUpdate,
                blockParentId,
            });
        } else
            createSingleResponseNode<SingleResponseNode<"function">>({
                data: {
                    type: "function",
                    is_not: false,
                    metadata: values,
                },
                sourceId,
                direction,
                blockParentId,
            });
        onNodeSaveCallBack();
    };

    const { handleSubmit, watch, setValue } = methods;
    const functionType = watch("function_type");

    useEffect(() => {
        setIsBlackCall(functionType === "blank_call");
        if (canUpdate.current) {
            setValue("min", null);
            setValue("max", null);
        } else {
            canUpdate.current = true;
        }
    }, [functionType]);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <BlockHeader
                title="Predefined Function"
                showCreateButton={showCreateButton}
            />
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Stack gap={1.5}>
                    <RHFTextField
                        name="name"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        placeholder="Block Name"
                        label="Block Name"
                    />
                    <RHFSelect
                        name="function_type"
                        className="w-full nodrag nopan"
                        variant="outlined"
                        size="small"
                        label="Select predefined function"
                        options={predefinedFunctionOptions}
                    />
                    <Stack gap={1} direction="row">
                        <RHFTextField
                            name="min"
                            className="w-full nowheel nodrag"
                            variant="outlined"
                            size="small"
                            label={isBlankCall ? "percentage" : "Min"}
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                        {isBlankCall ? (
                            <></>
                        ) : (
                            <RHFTextField
                                name="max"
                                className="w-full nowheel nodrag"
                                variant="outlined"
                                size="small"
                                label="Max"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                            />
                        )}
                    </Stack>
                </Stack>
            </Box>
        </FormProvider>
    );
}
