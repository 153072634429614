import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    SelectProps,
    FormHelperText,
    Stack,
    Typography,
    IconButton,
    SxProps,
    Theme,
    alpha,
} from "@mui/material";
import NoDataSvg from "../svg/NoDataSvg";
import { Clear } from "@mui/icons-material";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Label } from "../custom_components/Typography/Label";
import { pxToRem } from "@convin/utils/getFontValue";
import { SystemStyleObject } from "@mui/system";
import merge from "lodash.merge";

export interface Option<T extends string | number | null | undefined> {
    value: string;
    id: T;
    SVG?: React.FC<{ sx?: SxProps<Theme> }>;
}

export type GenericSelectProps<T extends string | number | null> =
    Partial<SelectProps> & {
        label: string;
        value: T | null;
        options: Option<T>[];
        handleChange: (event: T) => void;
        helperText?: string;
        hasClear?: boolean;
        className?: string;
    };

// eslint-disable-next-line react-refresh/only-export-components
export const borderlessVariantProps: (
    sx?: SystemStyleObject<Theme>
) => Pick<SelectProps, "sx" | "size" | "IconComponent"> = (sx) => ({
    sx: (theme) =>
        merge(
            {
                boxShadow: "none",
                height: "20px",
                "&.MuiAutocomplete-root": {
                    mb: 0.3,
                    "& .MuiInputBase-root,.MuiInputBase-input": {
                        py: "0px !important",
                        pl: "0px !important",
                    },
                },

                "& .MuiSelect-select": {
                    py: "0px !important",
                    pl: "0px !important",
                },

                ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,
                    },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,
                    },
                "& .MuiTypography-root,.MuiInputBase-input": {
                    fontWeight: 600,
                    fontSize: pxToRem(14),
                },

                "& .MuiSvgIcon-root": {
                    borderRadius: "100px",
                    bgcolor: alpha(theme.palette.textColors["999"], 0.15),
                    right: -2,
                    scale: "0.8",
                },

                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
            },
            sx ?? {}
        ),
    size: "small",
});

const GenericSelect = <T extends string | number | null>({
    label,
    value,
    options,
    handleChange,
    className = "",
    helperText = "",
    hasClear = false,
    customVaraint,
    size = "medium",
    ...rest
}: GenericSelectProps<T> & { customVaraint?: "borderless" }): JSX.Element => {
    const onChange = (e: SelectChangeEvent<unknown>) => {
        const value = (e.target.value === "null" ? null : e.target.value) as T;
        handleChange(value);
    };
    return (
        <FormControl
            variant="outlined"
            className={className}
            sx={{
                ".MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                },
            }}
        >
            <InputLabel
                sx={{
                    "&:after": {
                        content: className.includes("requiredMark")
                            ? '"*"'
                            : '""',
                        color: "#fd6c6f",
                    },
                }}
                size={size === "small" ? "small" : "normal"}
            >
                {label}
            </InputLabel>
            <Select
                value={
                    value === null && options.find((e) => e.id === null)
                        ? "null"
                        : value?.toString() ?? ""
                }
                onChange={onChange}
                label={label}
                error={!!rest.error}
                endAdornment={
                    hasClear && isDefined(value) ? (
                        <IconButton
                            onClick={() => handleChange(null as T)}
                            sx={{ scale: "0.9", mr: 1.5 }}
                        >
                            <Clear />
                        </IconButton>
                    ) : null
                }
                size={size}
                {...rest}
                {...(customVaraint === "borderless" &&
                    borderlessVariantProps())}
            >
                {options.length === 0 ? (
                    <MenuItem disabled>
                        <Stack
                            direction="column"
                            alignItems="center"
                            sx={{ p: 2 }}
                        >
                            <NoDataSvg />
                            <Typography variant="body2" className="semi-bold">
                                No data
                            </Typography>
                        </Stack>
                    </MenuItem>
                ) : (
                    options
                        .map((e) => (e.id === null ? { ...e, id: "null" } : e))
                        .map(({ id, value, SVG }, index) => (
                            <MenuItem
                                key={index}
                                value={id ?? ""}
                                className="flex items-center capitalize"
                                sx={{
                                    gap: 1,
                                    maxWidth: (theme) => theme.spacing(50),
                                }}
                            >
                                {isDefined(SVG) ? <SVG /> : null}
                                <Label colorType="333" isEllipses>
                                    {value}
                                </Label>
                            </MenuItem>
                        ))
                )}
            </Select>
            <FormHelperText error={!!rest.error}>
                <>{helperText}</>
            </FormHelperText>
        </FormControl>
    );
};

export default GenericSelect;
