import { PropsWithChildren, ReactNode } from "react";
import BertCardWrapper from "../BertCardWrapper";
import { Box, Skeleton, Typography, alpha, useTheme } from "@mui/material";
import { Label } from "@convin/components/custom_components/Typography/Label";
import SingleBlockNodeSvg from "../../svg/SingleBlockNodeSvg";
import useAnchorEl from "@convin/hooks/useAnchorEl";
import { MoreVert } from "@mui/icons-material";
import { NodeToolbar, Position } from "reactflow";
import GroupBlockNodeSvg from "../../svg/GroupBlockNodeSvg";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import DefaultSvg from "../../svg/DefalultSvg";
import NotOperatorLegendSvg from "../../svg/NotOperatorLegendSvg";

export default function BlockNodeUI({
    children,
    heading,
    name,
    isGroupNode,
    isDefaultRuleConfig,
    is_not,
    is_active,
    Extra = <></>,
    isLoading,
}: PropsWithChildren<{
    isGroupNode?: boolean;
    isDefaultRuleConfig?: boolean;
    heading: string;
    name: ReactNode;
    is_not?: boolean;
    is_active?: boolean;
    Extra?: ReactNode;
    isLoading?: boolean;
}>) {
    const theme = useTheme();
    const { isGroupBlockTree } = useReactFlowStateContextProvider();
    const { anchorEl, handleClick, handleClose, open } =
        useAnchorEl<HTMLDivElement>();
    return (
        <>
            <BertCardWrapper
                sx={{
                    width: "200px",
                    p: 1,
                    position: "relative",
                    boxSizing: "content-box",
                    ...(is_not && {
                        borderColor: theme.palette.ruleEngineColors.not,
                        borderBottomWidth: "5px",
                    }),
                    ...(is_active && {
                        "::after": {
                            content: "''",
                            border: "1px solid",
                            borderColor: "primary.main",
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            top: 0,
                            left: 0,
                            borderRadius: "inherit",
                            transform: "translate(-1px,-1px)",
                        },
                    }),
                    "*": {
                        boxSizing: "border-box",
                    },
                }}
                className="flex items-center relative"
                gap={1}
            >
                <>
                    {is_not && (
                        <Box
                            className="absolute flex items-center"
                            sx={{
                                top: -30,
                            }}
                        >
                            <Box
                                sx={{
                                    scale: "0.8",
                                }}
                            >
                                <NotOperatorLegendSvg />
                            </Box>
                            <Typography
                                variant="small"
                                color={theme.palette.ruleEngineColors.not}
                                className="font-semibold"
                            >
                                Not
                            </Typography>
                        </Box>
                    )}
                    <Box className="flex-shrink-0">
                        {isGroupNode ? (
                            <GroupBlockNodeSvg />
                        ) : isDefaultRuleConfig ? (
                            <DefaultSvg />
                        ) : (
                            <SingleBlockNodeSvg />
                        )}
                    </Box>
                    <Box className="flex flex-1 overflow-hidden">
                        <Box className="flex-1 flex flex-col overflow-hidden">
                            <Label
                                hasTooltip
                                isEllipses
                                colorType="666"
                                variant="extraSmall"
                                className="uppercase"
                            >
                                {heading}
                            </Label>
                            <Label
                                hasTooltip
                                isEllipses
                                colorType="333"
                                variant="medium"
                                className="font-semibold"
                            >
                                {isLoading ? (
                                    <Skeleton width={"200px"} />
                                ) : (
                                    name
                                )}
                            </Label>
                        </Box>
                        <Box className="flex flex-start" gap={1}>
                            {Extra}
                            <Box
                                sx={{
                                    bgcolor: (theme) =>
                                        alpha(
                                            theme.palette.textColors[999],
                                            0.1
                                        ),
                                    borderRadius: "6px",
                                }}
                                className="flex-shrink-0 w-[20px] h-[20px] flex items-center justify-center nodrag nopan cursor-pointer"
                                onClick={(e) => {
                                    anchorEl ? handleClose() : handleClick(e);
                                }}
                            >
                                <MoreVert
                                    sx={{
                                        transform: "scale(0.8)",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </>
            </BertCardWrapper>
            <NodeToolbar
                isVisible={open}
                position={isGroupBlockTree ? Position.Top : Position.Bottom}
                offset={3}
                className="pl-[60px]"
            >
                {children}
            </NodeToolbar>
        </>
    );
}
