import { Box, Typography } from "@mui/material";
import { CloseSvg } from "@convin/components/svg";
import AIRecommendationModal from "./AiRecommendation/AIRecommendationModal";

/**
 * MomentPhraseCard - A component to display a single phrase with AI recommendation and delete functionality
 * @component
 *
 * @param {Object} props - Component props
 * @param {string} [props.name=""] - The phrase text to display
 * @param {() => void} props.onClick - Callback function for delete action
 *
 * @example
 * ```tsx
 * <MomentPhraseCard
 *   name="Example phrase"
 *   onClick={() => handleDelete()}
 * />
 * ```
 *
 * @description
 * Displays a phrase in a card format with:
 * - Gray background (rgba(153, 153, 153, 0.10))
 * - Rounded corners (6px)
 * - AI recommendation button
 * - Delete button (CloseSvg)
 * - Consistent padding and margin
 */
export default function MomentPhraseCard({
    name = "",
    onClick,
}: {
    /** The phrase text to display */
    name: string;
    /** Callback function triggered when delete icon is clicked */
    onClick: () => void;
}) {
    return (
        <Box
            sx={{
                px: 1.5,
                py: 1,
                mx: 1.5,
                my: 1,
                background: "rgba(153, 153, 153, 0.10)",
                borderRadius: "6px",
            }}
            className="flex items-center justify-between"
        >
            {/* Phrase Text */}
            <Typography variant="small">{name}</Typography>

            {/* Action Buttons */}
            <Box className="flex items-center">
                <AIRecommendationModal phrase={name} />
                <CloseSvg onClick={onClick} />
            </Box>
        </Box>
    );
}
