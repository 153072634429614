import { Box, Stack } from "@mui/material";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFTextField } from "@convin/components/hook-form";
import { useReactFlowStateContextProvider } from "../hooks/useReactFlowStateContextProvider";
import type {
    MultiResponseBlockData,
    MultipleResponseNode,
} from "../../types/bert";
import BlockHeader from "./Blocks/BlockHeader";
import useNodeContext from "../hooks/useNodeContext";
import {
    getParameterResponseOptions,
    isDefined,
} from "@convin/utils/helper/common.helper";
import useParameterAiConfigContext from "../hooks/useParameterAiConfigContext";
import AIResponseSelector from "@convin/components/select/AIResponseSelector";
import { MultiResponseNodeType } from "../context/NodeStateContext";

// Create validation schema using Yup
const schema = yup.object().shape({
    name: yup.string().required("Name is Required"),
    blocks: yup
        .array()
        .of(
            yup.object().shape({
                data: yup.object(),
            })
        )
        .min(1, "Select at least one resposne"),
});

export default function CreateEditResponseNode() {
    const { createMultipleResponseNode, updateMultipleResponseNode } =
        useReactFlowStateContextProvider();
    const {
        question,
        aiConfigState: { selectedResponses },
    } = useParameterAiConfigContext();
    const {
        sourceId,
        direction,
        onNodeSaveCallBack,
        nodeDataToUpdate,
        nodeIdToUpdate,
        blockId,
    } = useNodeContext();
    const methods = useForm<MultiResponseBlockData<"response">>({
        resolver: yupResolver(schema),
        ...(isDefined(nodeDataToUpdate) && {
            values: nodeDataToUpdate as MultiResponseBlockData<"response">,
        }),
        defaultValues: {
            name: "",
            blocks: [],
        },
    });

    const { control, handleSubmit } = methods;

    const onSubmit = async (payload: MultiResponseBlockData<"response">) => {
        const formatBlocks = payload?.blocks?.map(({ label: _, ...e }) => ({
            ...e,
        }));
        if (isDefined(nodeIdToUpdate)) {
            updateMultipleResponseNode({
                sourceId: nodeIdToUpdate,
                data: { ...payload, blocks: formatBlocks },
            });
        } else
            createMultipleResponseNode<MultipleResponseNode<"response">>({
                data: {
                    type: "response",
                    metadata: {
                        ...payload,
                        blocks: formatBlocks,
                    },
                },
                sourceId,
                direction,
                blockId,
            });
        onNodeSaveCallBack();
    };

    const getBlock: (
        id: string | number
    ) =>
        | MultiResponseBlockData<MultiResponseNodeType>["blocks"][0]
        | undefined = (id) =>
        (
            nodeDataToUpdate as MultiResponseBlockData<MultiResponseNodeType>
        ).blocks.find((e) => e.id === id);

    const parameterOptions = getParameterResponseOptions(question).filter((e) =>
        nodeIdToUpdate
            ? (
                  nodeDataToUpdate as
                      | MultiResponseBlockData<MultiResponseNodeType>
                      | undefined
              )?.blocks
                  .map((e) => e.response)
                  .includes(String(e.id)) ||
              !selectedResponses.map(({ id }) => id).includes(e.id)
            : !selectedResponses.map(({ id }) => id).includes(e.id)
    );

    const options: Array<
        MultiResponseBlockData<MultiResponseNodeType>["blocks"][0] & {
            label: string;
        }
    > = parameterOptions.map((e) => {
        if (isDefined(nodeDataToUpdate)) {
            const block = getBlock(e.id);
            if (isDefined(block)) {
                return {
                    ...block,
                    label:
                        parameterOptions.find(({ id }) => id === e.id)?.label ??
                        "",
                };
            }
        }
        return {
            ...e,
            id: String(e.id),
            sourcePosition: null,
            parent: null,
            data: { type: null, is_not: false, metadata: {} },
            response: String(e.id),
        };
    });

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box className="w-full">
                <BlockHeader title="Response Block" showCreateButton />
                <Stack className="w-full" py={2.5} gap={2}>
                    <RHFTextField
                        name="name"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        placeholder="Response Name"
                    />
                    <Controller
                        name="blocks"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <AIResponseSelector<(typeof options)[0]>
                                label=""
                                autocompleteProps={{
                                    options: options,
                                    size: "small",
                                }}
                                values={field.value as typeof options}
                                setValues={(values) => {
                                    field.onChange(values);
                                }}
                                className="flex-1 nodrag nopan"
                                error={!!error}
                                helperText={error?.message}
                                placeholder="Choose Response Blocks"
                            />
                        )}
                    />
                </Stack>
            </Box>
        </FormProvider>
    );
}
