import { useContext } from "react";
import {
    INodeStateContext,
    NodeStateContext,
} from "../context/NodeStateContext";

/**
 * @description a custom hook which exposes the NodeStateContext for components
 * wrapped in the NodeStateProvider component
 *
 * @return {*}  {INodeStateContext}
 */
const useNodeContext = (): INodeStateContext => {
    const context = useContext(NodeStateContext);

    if (!context)
        throw new Error(
            "Node Creator context must be use inside BertProviderprovider"
        );

    return context;
};

export default useNodeContext;
