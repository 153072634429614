import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { SentimentDefinition } from "@convin/type/Sentiment";

export const sentimentAnalysisApiSlice = createApi({
    reducerPath: "sentimentAnalysisApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getSentimentDefinition: builder.query<SentimentDefinition, void>({
            query: () => "/gpt/sentiment_analysis/",
        }),
        updateSentimentDefinition: builder.mutation<
            SentimentDefinition,
            Pick<SentimentDefinition, "positive" | "negative">
        >({
            query: (payload) => ({
                url: "/gpt/sentiment_analysis/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    sentimentAnalysisApiSlice.util.updateQueryData(
                        "getSentimentDefinition",
                        undefined,
                        (draft) => {
                            Object.assign(draft, payload);
                        }
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export const {
    useGetSentimentDefinitionQuery,
    useUpdateSentimentDefinitionMutation,
} = sentimentAnalysisApiSlice;
