import React from "react";

import CreatorNode from "../CreatorNode";
import CreateMutiResponseBlockType from "./CreateMutiResponseBlockType";

export default function CreateMultipleResponseNode() {
    return (
        <CreatorNode>
            <CreateMutiResponseBlockType
                onNodeSaveCallBack={() => {}}
                showDefaultRuleButton
                hideResponseBlock
            />
        </CreatorNode>
    );
}
