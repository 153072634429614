import { ArrowDropDown, Clear } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    ClickAwayListener,
    IconButton,
    InputAdornment,
    Popper,
    TextField,
    TextFieldProps,
    Theme,
    alpha,
    styled,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import { VirtualizeProps } from "@convin/type/Common";
import { pxToRem } from "@convin/utils/getFontValue";
import { isDefined } from "@convin/utils/helper/common.helper";

interface Props<T extends { id: number | string; label: string }> {
    virtualizeProps: VirtualizeProps;
    value: T["id"] | null;
    setValue: (id: T["id"] | null, object?: T) => void;
    options: T[];
    label: string;
    error?: boolean;
    helperText?: string;
    placeholder?: string;
    onQueryChange?: (
        value: string,
        reason: "select" | "clear" | "type"
    ) => void;
    className?: string;
    hasClear?: boolean;
    hasCreate?: boolean;
    createLabel?: string;
    onCreateClick?: () => void;
    size?: TextFieldProps["size"];
    disabled?: boolean;
}

const popperSx = (theme: Theme) => ({
    zIndex: 1500,
    background: theme.palette.background.paper,
    minWidth: "200px",
    maxHeight: "300px",
    overflowY: "scroll",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    borderRadius: "6px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
});

const StyledOption = styled("li", {
    shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(({ theme, active }) => ({
    listStyle: "none",
    padding: "12px",
    cursor: "pointer",
    fontSize: pxToRem(14),
    "&:hover": {
        background: alpha(theme.palette.textColors[999], 0.1),
    },
    background: active
        ? alpha(theme.palette.primary.main, 0.1)
        : theme.palette.background.paper,
}));

const InfiniteScrollSelect = <T extends { id: number | string; label: string }>(
    props: Props<T>
) => {
    const {
        label,
        value,
        setValue,
        error = false,
        helperText = "",
        placeholder = "Search",
        options,
        className = "",
        virtualizeProps: { isFetching, isLoading, hasNext, fetchNext },
        onQueryChange = () => null,
        hasClear = false,
        hasCreate = false,
        createLabel = "Add",
        onCreateClick = () => {},
        size,
        disabled,
    } = props;
    const ref = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [query, setQuery] = useState<{
        value: string;
        reason: "select" | "clear" | "type";
    }>({ value: "", reason: "clear" });

    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: HTMLLIElement | null) => {
            if (isLoading || isFetching) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNext) {
                    fetchNext();
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading, isFetching, hasNext]
    );

    useEffect(() => {
        onQueryChange(query.value, query.reason);
    }, [query]);

    useEffect(() => {
        if (value) {
            setQuery({
                value:
                    options.find((option) => option.id === value)?.label ??
                    `${value}`,
                reason: "select",
            });
            return () => {
                if (value !== query.value) {
                    setQuery({
                        value: "",
                        reason: "clear",
                    });
                }
            };
        }
    }, [value]);

    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    setOpen(false);
                }}
            >
                <Box
                    ref={ref}
                    sx={{
                        ".MuiInputBase-root": {
                            pr: 0,
                        },
                    }}
                >
                    <TextField
                        label={label}
                        placeholder={placeholder}
                        error={error}
                        className={`${className} w-full`}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setValue(null);
                            }
                            setQuery({
                                value: e.target.value,
                                reason: "type",
                            });
                            setOpen(true);
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen((prev) => !prev);
                        }}
                        disabled={disabled}
                        value={query.value}
                        helperText={<>{helperText}</>}
                        size={size}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{ pr: 0.6 }}>
                                    {hasClear && isDefined(value) ? (
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setValue(null);
                                                setQuery({
                                                    value: "",
                                                    reason: "clear",
                                                });
                                            }}
                                            sx={{ scale: "0.9" }}
                                        >
                                            <Clear />
                                        </IconButton>
                                    ) : null}
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setOpen((prev) => !prev);
                                        }}
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.textColors[666],
                                        }}
                                        size="small"
                                    >
                                        {isFetching ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : (
                                            <ArrowDropDown />
                                        )}
                                    </IconButton>{" "}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </ClickAwayListener>

            <Popper
                open={open}
                anchorEl={ref.current}
                sx={(theme) => ({
                    ...popperSx(theme),
                    width: ref.current?.clientWidth ?? 200,
                })}
                placement="bottom"
            >
                {hasCreate ? (
                    <Button
                        variant="text"
                        className="w-full justify-start"
                        onClick={() => {
                            setOpen(false);
                            onCreateClick();
                        }}
                    >
                        + {createLabel}
                    </Button>
                ) : null}
                {options.map((option, index) => (
                    <StyledOption
                        key={option.id}
                        id={option.id?.toString()}
                        {...(index === options.length - 1
                            ? { ref: lastElementRef }
                            : {})}
                        active={value === option.id}
                        onClick={() => {
                            setValue(option.id, option);
                            setQuery({
                                value: option.label,
                                reason: "select",
                            });
                            setOpen(false);
                        }}
                    >
                        {option?.label ?? ""}
                    </StyledOption>
                ))}
                {isLoading || isFetching ? (
                    <StyledOption>Loading...</StyledOption>
                ) : !options.length ? (
                    <StyledOption>No options</StyledOption>
                ) : null}
            </Popper>
        </>
    );
};

export default InfiniteScrollSelect;
