import React, {
    PropsWithChildren,
    ReactElement,
    useReducer,
    useState,
} from "react";
import {
    IBlockState,
    NodeStateContext,
    NodeStateDispatchAction,
    NodeStateProviderProps,
} from "./NodeStateContext";

const intialState: IBlockState = {
    buildingBlockType: null,
    selectedBlockType: null,
};

function Reducer(state: IBlockState, action: NodeStateDispatchAction) {
    const { type, payload } = action;
    switch (type) {
        case "UPDATE":
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
}

/**
 * NodeStateProvider is a context provider that manages the block-related state.
 * It provides the current state, dispatch, and additional
 * utilities for managing block creation.
 *
 * @param {PropsWithChildren<NodeStateProviderProps>} props - The properties passed to the provider.
 * @param {React.ReactNode} props.children - The child components to be rendered within the provider.
 * @param {any} props.rest - Additional properties passed to the context provider.
 *
 * @returns {ReactElement} The NodeStateContext.Provider with the current state and utilities.
 */
export default function NodeStatePorvider({
    children,
    ...rest
}: PropsWithChildren<NodeStateProviderProps>): ReactElement {
    const [state, dispatch] = useReducer(Reducer, intialState);
    const [createBlock, setCreateBlock] = useState<boolean>(false);
    return (
        <NodeStateContext.Provider
            value={{
                state,
                dispatch,
                createBlock,
                setCreateBlock,
                ...rest,
            }}
        >
            {children}
        </NodeStateContext.Provider>
    );
}
